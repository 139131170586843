import { Box, Grid, Skeleton, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";

const MargedBox = styled(Box)({
  margin: "8px",
});

const Card = styled(Skeleton)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadiusMd,
}));

const HomeStatsAdvisorSkeleton: React.FC<{ row?: boolean }> = ({
  row = false,
}) => {
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" height="38px">
        <Typography variant="body1" fontWeight="bold">
          {t(
            row ? "admin.graph.title" : `common_advisor.dashboard.global_view`,
            { context }
          )}
        </Typography>
      </Box>
      <Grid container display="flex">
        <Grid item md={row ? 12 : 6} xs={12}>
          <MargedBox>
            <Card variant="rectangular" width="100%" height="312px" />
          </MargedBox>
        </Grid>
        <Grid item md={row ? 12 : 6} xs={12}>
          <MargedBox>
            <Card variant="rectangular" width="100%" height="312px" />
          </MargedBox>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeStatsAdvisorSkeleton;
