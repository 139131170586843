import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
  Skeleton,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { useTranslation } from "react-i18next";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount: number;
  headCells: any;
  loading?: boolean;
  canSelect?: boolean;
  reference?: any;
}

function CustomTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    loading,
    canSelect,
    reference,
  } = props;
  const { t } = useTranslation("common");
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <TableHead ref={reference}>
      <TableRow>
        {canSelect && (
          <TableCell padding="checkbox">
            {loading ? (
              <LoaderCheckbox />
            ) : (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all",
                }}
              />
            )}
          </TableCell>
        )}
        {headCells
          .filter((el: any) => !el.hide)
          ?.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align={headCell.align ?? "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {loading ? (
                <Skeleton variant="text" />
              ) : headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.sortableKey}
                  direction={orderBy === headCell.sortableKey ? order : "asc"}
                  onClick={createSortHandler(headCell.sortableKey)}
                >
                  {capitalize(t(headCell.label))}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                capitalize(t(headCell.label))
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;

const LoaderCheckbox = () => {
  return (
    <Box
      sx={{
        width: 42,
        height: 42,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Skeleton variant="rectangular" width={20} height={20} />
    </Box>
  );
};
