import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box, styled } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSelector, shallowEqual } from "react-redux";
import AccessWorkSwitch from "./AccessWorkSwitch";
import SimplifiedSwitch from "./SimplifiedSwitch";
import TrainingsSwitch from "./TrainingsSwitch";
import { ProfileContext } from "../../contexts/ProfileContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const DownloadButton = styled(Button)(({ theme }: any) => ({
  backgroundColor: theme.palette.grey[500_8],
  color: theme.palette.primary.main,
  fontSize: "12px",
  fontWeight: "normal",
}));

const AccessMenu: React.FC<any> = () => {
  const { context } = useSelector((state: any) => state.auth);
  const home = useSelector((state: any) => state.home, shallowEqual);
  const { t } = useTranslation("common");
  const menu = useSelector((state: any) => state.user.menu);
  const { peopleData } = useContext(ProfileContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <DownloadButton aria-describedby={id} onClick={handleClick}>
        <SettingsIcon
          color="primary"
          sx={{ fontSize: "22px", marginRight: "5px" }}
        />
        {t("common_advisor.profile.access_selector")}
      </DownloadButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "10px 0px 0px 10px",
          }}
        >
          {context === "advisor" && (
            <>
              <SimplifiedSwitch />
            </>
          )}

          {context === "advisor" && menu?.advisor?.works && (
            <>
              <AccessWorkSwitch />
            </>
          )}

          {home?.paramjson?.menu?.trainings &&
            context === "advisor" &&
            !peopleData?.simplified && (
              <>
                <TrainingsSwitch />
              </>
            )}
        </Box>
      </Popover>
    </>
  );
};

export default AccessMenu;
