import React from "react";
import { HighlightOff } from "@mui/icons-material";
import {
  Modal,
  Card,
  IconButton,
  styled,
  CardContent,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import { modalStyle } from "../../../../_utils/modalStyle";
import CustomButtonModal from "../../../common/CustomBtnModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Utils } from "../../../../Utils";

const BodyRoot = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 10px;
`;

const RemoveAdvisorModal: React.FC<any> = ({ open, handleClose, confirm }) => {
  const { t } = useTranslation("common");
  const { user_id } = useSelector((state: any) => state.user);
  const { context } = useSelector((state: any) => state.auth);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleConfirm = () => {
    if (checked) {
      const txt = `${user_id}_adminRemoveAdvisorModal`;
      Utils.setCookies(txt, "true");
    }
    confirm(open, true);
    handleClose();
  };

  return (
    <Modal open={Boolean(open)} onClose={handleClose}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", lg: "500px", md: "500px" },
          minHeight: "200px",
        }}
      >
        <BodyRoot>
          <CloseButton onClick={handleClose} aria-label="close modal">
            <HighlightOff />
          </CloseButton>
        </BodyRoot>
        <CardContent>
          <Typography sx={{ mt: -1, mb: 2 }} variant="h3" textAlign="center">
            {t("common_advisor.remove_modal.title", { context })}
          </Typography>
          <Stack spacing={2}>
            <Typography textAlign="center">
              {t("common_advisor.remove_modal.subtitle", { context })}
            </Typography>
            <Typography>
              {t("common_advisor.remove_modal.text", { context })}
            </Typography>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label={t("common_advisor.remove_modal.do_not_show_again")}
              />
            </FormGroup>
          </Stack>
        </CardContent>

        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
        >
          <CustomButtonModal
            secondary
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            text={t(`btn.cancel`)}
          />
          <CustomButtonModal
            type="submit"
            onClick={handleConfirm}
            variant="contained"
            text={t("btn.valid")}
          />
        </Box>
      </Card>
    </Modal>
  );
};

export default RemoveAdvisorModal;
