import { Box, styled, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const IconBox: any = styled(Box, {
  shouldForwardProp: (props) => props !== "type",
})(({ type, theme }: any) => ({
  padding: "10px",
  width: "40px",
  height: "40px",
  borderRadius: theme.shape.borderRadius8,
  ...(type === "people" && {
    marginLeft: "auto",
    marginRight: "9px",
    backgroundColor: theme.palette.green[500],
    color: theme.palette.common.white,
  }),
  ...(type === "suitcase" && {
    marginRight: "auto",
    backgroundColor: theme.palette.primary.main,
  }),
}));

const FolderName = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.green[500],
  textAlign: "center",
  marginBottom: "24px",
}));

const Subtext = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary[400],
  textAlign: "center",
  marginBottom: "24px",
}));

const BenefWorkMatchingHeader: React.FC<{ folderData: any }> = ({
  folderData,
}) => {
  const context = useSelector((state: any) => state.auth.context);
  const { t } = useTranslation("common");
  return (
    <>
      <Box sx={{ display: "flex", width: "100%", mb: "15px", mt: "5px" }}>
        <Box sx={{ display: "flex", flexGrow: 1, ml: "16px" }}>
          <IconBox type="people">
            <PeopleIcon sx={{ width: "20px", height: "20px" }} />
          </IconBox>
          <IconBox type="suitcase">
            <img
              src="/static/icons/suitcase.svg"
              alt="suitcase icon"
              width="20px"
            />
          </IconBox>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">
          {t("common_advisor.folder.work_matching.title_1")}
        </Typography>
        <FolderName variant="h4">&nbsp;{folderData?.name}</FolderName>
      </Box>
      <Subtext variant="body2">
        {t("common_advisor.folder.work_matching.subtext1")}
      </Subtext>
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        sx={{ mb: "18px", textAlign: "center" }}
      >
        {t("common_advisor.folder.work_matching.subtext2", { context })}
      </Typography>
    </>
  );
};

export default BenefWorkMatchingHeader;
