import { Box, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import companiesAPI from "../../../_api/_companiesAPI";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import InventoryIcon from "@mui/icons-material/Inventory";

const AdminRecruiterCredits = () => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { data, loading } = useFetch(
    companiesAPI.getById(`${tokenData.company_id}/credits`)
  );
  const context = useSelector((state: any) => state.auth.context);

  const [creditList, setCreditList] = useState<any>([]);
  useEffect(() => {
    data?.creditUsed &&
      setCreditList([
        {
          label: t("common_advisor.profile.label_plural", { context }),
          used: data?.creditUsed.cntCandidate,
          icon: <PersonIcon color="primary" sx={{ fontSize: "30px" }} />,
        },
        {
          label: "Archivés",
          used: data?.creditUsed.cntCandidateArchived,
          icon: <InventoryIcon color="primary" sx={{ fontSize: "30px" }} />,
        },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading || !data?.limited) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t("admin.params.credits.title")}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-around"
      >
        {creditList?.map((item: any) => (
          <Paper
            elevation={3}
            key={item.label}
            sx={{ m: 1, p: 2, minWidth: "200px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.background.neutral,
                }}
              >
                {item.icon}
              </Box>
            </Box>

            <Typography
              variant="body1"
              fontWeight="bold"
              textAlign="center"
              sx={{ mt: 2 }}
            >
              {item?.label}
            </Typography>
            <Typography
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="h4"
              fontWeight="bold"
              color="primary"
              textAlign="center"
            >
              {item?.used ?? 0}
            </Typography>
          </Paper>
        ))}
        <Paper elevation={3} sx={{ m: 1, p: 2, minWidth: "180px" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: theme.palette.background.neutral,
              }}
            >
              <SavingsOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
            </Box>
          </Box>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            {t("admin.params.credits.rest")}
          </Typography>
          <Typography
            sx={{ mt: 1 }}
            variant="h4"
            fontWeight="bold"
            color="primary"
            textAlign="center"
          >
            {data?.creditLeft} / {data?.creditTotal}
          </Typography>
        </Paper>
      </Box>
    </>
  );
};

export default AdminRecruiterCredits;
