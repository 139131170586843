import { Navigate, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import LogoLoader from "../components/LogoLoader";
import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";

import ProfileContextProvider from "./../contexts/ProfileContext";
import TestsPage from "../pages/TestsPage";
import Big5Page from "../pages/Big5Page";
import CulturefitPage from "../pages/CulturefitPage";
import IncPage from "../components/inc/IncPage";
import ProjectsPage from "../pages/ProjectsPage";
import AccountParamsPage from "../pages/AccountParamsPage";
import AccountDataPage from "../pages/AccountDataPage";
import IncTestsPage from "../components/inc/IncTestsPage";
import IncTestContextProvider from "../components/inc/test/IncTestContext";
import Chat from "../components/Chat/Chat";
import IncGamePage from "../pages/IncGamePage";
import RefuseInvitationPage from "../pages/RefuseInvitationPage";
import { useSelector } from "react-redux";
import WorkTrainingsPage from "../pages/WorkTrainingsPage";
import WorkTrainingPage from "../pages/WorkTrainingPage";
import RedirectPlatform from "../components/RedirectPlatform";
import PrivacyPolicy from "../pages/PrivacyPolicy";
// import NoSideBarLayout from "../layout/NoSideBarLayout";

const WorkPage = lazy(() => import("../pages/WorkPage"));
const NotFound = lazy(() => import("../pages/NotFound"));

const Home = lazy(() => import("../pages/Home"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const CguPage = lazy(() => import("../pages/CguPage"));
const JobSuggestPage = lazy(() => import("../pages/JobSuggestPage"));
const WorkSuggestPage = lazy(() => import("../pages/WorkSuggestPage"));
const MoodPage = lazy(() => import("../pages/MoodPage"));
const RessourcesPage = lazy(() => import("../pages/RessourcesPage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));
const OfferPage = lazy(() => import("../pages/third_party/OfferPage"));
// ----------------------------------------------------------------------

export default function Router() {
  const { context, redirectUrl } = useSelector((state: any) => state.auth);
  const menu = useSelector((state: any) => state.menu);

  const redirectPath = sessionStorage.getItem("disconnected-path");

  const baseRedirect =
    context === "candidate"
      ? menu?.profile
        ? "/app/profile/overview"
        : "/app/tests"
      : redirectPath ?? "/app/home";

  return useRoutes([
    {
      path: "login",
      element: <Navigate to={redirectUrl ?? baseRedirect} replace />,
    },
    {
      path: "app",
      element: <FullLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={baseRedirect} replace />,
        },

        context !== "candidate" && {
          path: "home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Home />
            </Suspense>
          ),
        },

        {
          path: "chat",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              {context === "benef" ? <Chat /> : <Navigate to="/404" />}
            </Suspense>
          ),
        },

        menu?.profile && {
          path: "profile",
          element: <Navigate to="/app/profile/overview" replace />,
        },
        menu?.profile && {
          path: "profile/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            </Suspense>
          ),
        },
        {
          path: "tests",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <TestsPage />
            </Suspense>
          ),
        },
        {
          path: "big5",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <Big5Page />
            </Suspense>
          ),
        },
        {
          path: "culturefit",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CulturefitPage />
            </Suspense>
          ),
        },
        {
          path: "inc",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <IncPage />
            </Suspense>
          ),
        },

        {
          path: "inc/test",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <IncTestContextProvider>
                <IncTestsPage />
              </IncTestContextProvider>
            </Suspense>
          ),
        },

        menu?.projects && {
          path: "projects",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ProjectsPage />
            </Suspense>
          ),
        },
        {
          path: "mood",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <MoodPage />
            </Suspense>
          ),
        },

        menu?.trainings && {
          path: "trainings",
          children: [
            { path: "", element: <Navigate to="/app/trainings/list" /> },
            {
              path: "list",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkTrainingsPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkTrainingPage />
                </Suspense>
              ),
            },
          ],
        },

        menu?.works && {
          path: "work",
          children: [
            { path: "", element: <Navigate to="/app/work/suggest" /> },
            {
              path: "suggest",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkSuggestPage />
                </Suspense>
              ),
            },
            {
              path: ":id",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <WorkPage />
                </Suspense>
              ),
            },
          ],
        },

        menu?.jobs && {
          path: "job/suggest",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <JobSuggestPage />
            </Suspense>
          ),
        },

        menu?.jobs && {
          path: "job/:id",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <OfferPage />
            </Suspense>
          ),
        },

        menu?.settings && {
          path: "account",
          children: [
            {
              path: "",
              element: <Navigate to="/app/account/params" replace />,
            },
            {
              path: "params",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountParamsPage />
                </Suspense>
              ),
            },
            {
              path: "data",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <AccountDataPage />
                </Suspense>
              ),
            },
          ],
        },
        menu?.resources && {
          path: "resources",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RessourcesPage />
            </Suspense>
          ),
        },
      ].filter((el) => el),
    },
    {
      path: "app/inc-game",
      element: (
        <Suspense fallback={<LogoLoader open={true} />}>
          <IncGamePage />
        </Suspense>
      ),
    },
    {
      path: "game",
      element: (
        <Suspense fallback={<LogoLoader open={true} />}>
          <IncGamePage />
        </Suspense>
      ),
    },

    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "onboarding",
          element: <Navigate to="/app/home" replace />,
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "refused-invitation",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RefuseInvitationPage />
            </Suspense>
          ),
        },
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "rdb",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RedirectPlatform />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "", element: <Navigate to="/app/home" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
