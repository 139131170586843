import React, { useEffect, useState } from "react";
import beneficiaryStatusesAPI from "../../_api/_beneficiaryStatusesAPI";
import {
  Box,
  Tooltip,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
} from "@mui/material";
import peopleAPI from "../../_api/_people";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Status: React.FC<{
  personId: string;
  currentStatus: any;
  updateStatus?: (status: any) => void;
  profile?: boolean;
}> = ({ personId, currentStatus, updateStatus, profile }) => {
  const { t } = useTranslation("common");

  const [status, setStatus] = useState<any>(currentStatus);
  const [statusPossible, setStatusPossible] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(
    currentStatus?.code
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    // if (currentStatus) {
    if (!profile) {
      setSelectedStatus(currentStatus?.code);

      setStatus((current: any) => {
        setHasChanged(current?.code !== currentStatus?.code);
        return currentStatus;
      });
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  useEffect(() => {
    if (!profile) {
      updateStatus?.(status);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async () => {
    hasChanged && (await handleClick());

    setOpen(true);
  };

  const handleClick = async () => {
    setHasChanged(false);
    const apiUrl = `${beneficiaryStatusesAPI.url}/next?person.id=${personId}&current=${status?.code}`;

    if (!personId || !currentStatus) return;
    setLoading(true);
    try {
      const response = await axios.get(apiUrl);
      setStatusPossible(response?.data["hydra:member"]);
    } catch (error) {
      console.error("Error fetching next status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (status: any) => {
    try {
      setLoading(true);
      await peopleAPI.patch(`${personId}/status`, {
        status: status,
      });

      const newStatus = statusPossible.find(
        (item: any) => item.code === status
      );
      setStatus(newStatus);

      updateStatus?.(newStatus);

      toast.success(t("profilepage.success_edit_status"));
      await handleClick();
    } catch (err) {
      toast.error(t("errors.error_append"));
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (event: any) => {
    setSelectedStatus(event.target.value);
    handleStatusChange(event.target.value);
    setOpen(false);
  };

  useEffect(() => {
    const updatedStatus = statusPossible.find(
      (item: any) => item.code === selectedStatus
    );
    if (updatedStatus) {
      setStatus(updatedStatus);
    }
  }, [selectedStatus, statusPossible]);

  return (
    <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
      {loading ? (
        <Chip
          sx={{
            backgroundColor: status?.color,
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
          label={
            <>
              {status?.status}
              <CircularProgress size={14} sx={{ color: "white", ml: 1 }} />
            </>
          }
        />
      ) : (
        <Tooltip title={status?.description} placement="top-start">
          {statusPossible.length > 0 ? (
            <Select
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              value={selectedStatus}
              onChange={handleSelectChange}
              autoWidth
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  sx={{ color: "white" }}
                  fontSize="small"
                />
              )}
              sx={{
                height: 30,
                backgroundColor: status?.color,
                color: "white",
                paddingRight: 1.5,
                borderRadius: 2,
                border: 0,
                "&:hover": {
                  border: 0,
                  outline: "none",
                },
                "&:focus": {
                  border: 0,
                  outline: "none",
                },
                "&:focus-visible": {
                  border: 0,
                  outline: "none",
                },
                "& .MuiOutlinedInput-input": {
                  paddingRight: "1px !important",
                },
              }}
            >
              <MenuItem
                sx={{ display: "none" }}
                key={status?.code}
                value={status?.code}
              >
                {status?.status}
              </MenuItem>

              {statusPossible?.map((item: any) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.status}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Chip
              sx={{ backgroundColor: status?.color, color: "white" }}
              onClick={handleClick}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {status?.status}
                  <KeyboardArrowDownIcon fontSize="small" />
                </Box>
              }
            />
          )}
        </Tooltip>
      )}
    </Box>
  );
};

export default Status;
