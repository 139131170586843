import { Box, Card, CardContent, Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";

const PrivacyPolicy = () => {
  const { t } = useTranslation("common");
  return (
    <Page title={t("privacy_policy.title")}>
      <Container sx={{ p: 5 }}>
        <Card>
          <CardContent>
            <Box
              dangerouslySetInnerHTML={{
                __html: `${t("privacy_policy.content")}`,
              }}
            ></Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default PrivacyPolicy;
