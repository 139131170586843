import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LogoLoader from "../components/LogoLoader";
import AdminWorkInfosPage from "../components/_admin/params/works/AdminWorkInfosPage";

import FullLayout from "../layout/FullLayout";
import NoMenuLayout from "../layout/NoMenuLayout";
import AdminParamsPage from "../pages/AdminParamsPage";

import CguPage from "../pages/CguPage";

import AdminParamsContextProvider from "./../contexts/AdminParamsContext";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const AdminHome = lazy(() => import("../components/_admin/AdminHome"));
const NotFound = lazy(() => import("../pages/NotFound"));
const UsersManagementPage = lazy(() => import("../pages/UsersManagementPage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));

// ----------------------------------------------------------------------

const baseRedirect = sessionStorage.getItem("disconnected-path") ?? "/app/home";

export default function AdminRoutes() {
  return useRoutes([
    {
      path: "login",
      element: <Navigate to={baseRedirect} replace />,
    },
    {
      path: "app",
      element: <FullLayout />,
      children: [
        { path: "", element: <Navigate to="/app/home" replace /> },
        {
          path: "home",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminHome />
            </Suspense>
          ),
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <UsersManagementPage />
            </Suspense>
          ),
        },
        {
          path: "params/:tab",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminParamsPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        {
          path: "params/work",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminWorkInfosPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
        {
          path: "params/work/:id",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdminParamsContextProvider>
                <AdminWorkInfosPage />
              </AdminParamsContextProvider>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        { path: "/", element: <Navigate to="/app" replace /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
