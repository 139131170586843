import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
export default function NoMenuLayout() {
  const { t } = useTranslation("common");
  const home = useSelector((state: any) => state.home, shallowEqual);

  const location = useLocation();

  const hasPP = Boolean(t("privacy_policy.content"));

  return (
    <div style={{ position: "relative" }}>
      <Outlet />
      {location?.pathname !== "/game" && (
        <Box
          id="footer-no-menu"
          sx={{
            position: "absolute",
            right: "12px",
            color: "#A0A0A0",
          }}
        >
          <Typography sx={{ fontSize: "12px", margin: { xs: 1, sm: 0 } }}>
            {t("footer.all_rights", { year: new Date().getFullYear() })}
            <a
              href="/cgu"
              target="_blank"
              style={{ color: "#4c6fff", textDecoration: "none" }}
            >
              {t("footer.cgu")}
            </a>{" "}
            {hasPP && (
              <>
                {", "}
                <a
                  href="/privacy_policy"
                  target="_blank"
                  style={{ color: "#4c6fff", textDecoration: "none" }}
                >
                  {t("privacy_policy.title")}
                </a>{" "}
              </>
            )}
            {t("footer.contact")}
            <a
              href={`mailto:${
                home?.paramjson?.supportMail ?? "contact@monkey-tie.com"
              }`}
              style={{ color: "#4c6fff", textDecoration: "none" }}
            >
              {home?.paramjson?.supportMail ?? "contact@monkey-tie.com"}
            </a>
          </Typography>
        </Box>
      )}
    </div>
  );
}
