import { TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useFetch from "../hooks/useFetch";
import tagsAPI from "../_api/_tagsAPI";
import tagEntitiesAPI from "../_api/_tagEntitiesAPI";
import { toastError } from "../_utils/toastError";

const AutocompleteTag = ({
  workId,
  personId,
  type,
  tagEntities,
  setTagEntities,
}: any) => {
  const { t }: any = useTranslation("common");

  const [tags, setTags] = useState<any>([]);

  const [value, setValue] = useState<TagType | null>(null);

  const filter = createFilterOptions<TagType>();

  const parametersFetch = useFetch("parameters");

  const homeParam = parametersFetch?.data?.["hydra:member"]?.find(
    (el: any) => el.type === "home.param"
  );

  const canCreateTag = homeParam?.parameters?.menu?.createTag !== false;

  const entityUrl =
    type === "person" ? `/api/people/${personId}` : `/api/works/${workId}`;

  const { data } = useFetch(
    tagsAPI.get({
      type: type,
    })
  );

  useEffect(() => {
    if (!tagEntities || !data?.["hydra:member"]) return;

    const all = data?.["hydra:member"] ?? [];

    const entitiesTags = tagEntities.map((el: any) => el.tag);

    const t = all.filter(
      (el: any) =>
        !Boolean(entitiesTags.find((e: any) => e["@id"] === el["@id"]))
    );

    setTags(t);
  }, [tagEntities, data, type]);

  const handleAddTag = async (newValue: any) => {
    if (newValue) {
      // Vérifier si newValue est une chaîne de caractères
      const label = typeof newValue === "string" ? newValue : newValue.label;

      try {
        // Vérifier si label existe déjà dans tagsAPI

        const existingTag = Boolean(tags.find((el: any) => el.label === label));

        if (existingTag) {
          try {
            const res = await tagEntitiesAPI.post({
              tag: newValue["@id"],
              entity: entityUrl,
            });

            setTagEntities((current: any) => [...current, res.data]);
          } catch (error) {
            toastError();
            console.error("Erreur lors de l'ajout du tag:", error);
          }
        } else {
          if (canCreateTag) {
            // Si le tag n'existe pas, effectuer un POST
            createTag(label);
          }
        }
      } catch (error) {
        toastError();
        console.error("Erreur lors de la gestion du tag :", error);
      }
    }
  };

  //Fonction de création d'un nouveau tag

  const createTag = async (value: any) => {
    if (value) {
      const label = value.charAt(0).toUpperCase() + value.slice(1);
      try {
        const response = await tagsAPI.post({
          label: label,
          color: "#708CFF",
          type: type,
        });
        try {
          const res = await tagEntitiesAPI.post({
            tag: response.data["@id"],
            entity: entityUrl,
          });

          setTagEntities((current: any) => [...current, res.data]);
        } catch (error) {
          toastError();
          console.error("Erreur lors de l'ajout du tag:", error);
        }
      } catch (error) {
        toastError();
        console.error("Erreur lors de la création d'un tag:", error);
      }
    }
  };

  interface TagType {
    inputValue?: string;
    "@id"?: string;
    "@type"?: string;
    id?: string;
    label?: string;
    color?: string;
    company?: string;
    context?: string;
  }

  return (
    <div>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              label: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setValue({
              label: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          const isExisting = options.some(
            (option) => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting && canCreateTag) {
            filtered.push({
              inputValue,
              label: `${inputValue}`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        clearOnEscape
        handleHomeEndKeys
        id="tag"
        options={tags}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return option.label || "";
        }}
        renderOption={(props, option) => (
          <li {...props} onClick={() => handleAddTag(option)}>
            <Typography>{option.label}</Typography>
            {!Boolean(tags.find((el: any) => el.label === option.label)) &&
              (canCreateTag ? (
                <Typography color="primary" sx={{ marginLeft: "auto" }}>
                  {t("btn.add")}
                </Typography>
              ) : null)}
          </li>
        )}
        sx={{
          width: 200,
        }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={t("tag.add_tags")} />
        )}
      />
    </div>
  );
};

export default AutocompleteTag;
