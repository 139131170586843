import { FormControl } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import { QuillStyle } from "../../_utils/quillStyle";

const ControlledQuill = ({
  name,
  placeholder,
  control,
  noMargin,
  style,
  toolbar,
  readOnly,
}: any) => {
  const modules = {
    toolbar: [
      // [{ size: ["small", false, "large"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <FormControl sx={{ width: "100%", mt: noMargin ? 0 : 2 }}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <ReactQuill
            readOnly={readOnly}
            theme={toolbar ? "snow" : ""}
            style={!toolbar ? style ?? QuillStyle : {}}
            placeholder={placeholder}
            {...(toolbar ? { formats, modules } : {})}
            {...field}
          />
        )}
      ></Controller>
    </FormControl>
  );
};

export default ControlledQuill;
