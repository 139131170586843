import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../_utils/modalStyle";
import RecruiterTransferInput from "./RecruiterTransferInput";
import { useSelector } from "react-redux";

const CardImage = styled("img")({
  position: "relative",
  left: "24%",
  width: "45%",
  marginTop: 1,
});

const TextWithHighlight = styled(Typography)(({ theme }: any) => ({
  "> .highlight": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const ActionButton = styled(Button)`
  font-size: 14px;
  padding: 8px 22px;
`;

const AdvisorDeleteModal: React.FC<any> = ({
  open,
  close,
  deleteUser,
  loading,
  fullName,
  recruiterId,
}) => {
  const [recruiterToId, setRecruiterToId] = useState<string | null>(null);
  const context = useSelector((state: any) => state.auth.context);
  const confirm = () => {
    deleteUser(recruiterToId);
  };

  const { t } = useTranslation("common");

  const handleValue = (value: any) => {
    setRecruiterToId(value?.id);
  };

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "500px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            disabled={loading}
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ mt: -6, textAlign: "center" }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t("admin.users.delete")}
          </Typography>

          <CardImage
            alt="Logout icon"
            src="/static/illustrations/Logout-illu.svg"
          />

          <Typography marginTop={2} variant="subtitle1">
            {t("admin.users.ask_delete")}
          </Typography>
          <Typography variant="subtitle1" color="error">
            {t("warning.undoable")}
          </Typography>
        </CardContent>

        <Box sx={{ paddingLeft: 3, paddingRight: 3, mb: 3 }}>
          <TextWithHighlight
            sx={{ mb: 1 }}
            dangerouslySetInnerHTML={{
              __html: t("common_advisor.transfer_to", {
                name: fullName,
                context,
              }),
            }}
          />
          <RecruiterTransferInput
            recruiterId={recruiterId}
            getValue={handleValue}
          />
        </Box>

        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: -1,
            mb: 5,
          }}
        >
          <ActionButton
            disabled={loading || !Boolean(recruiterToId)}
            onClick={confirm}
            variant="contained"
            color="error"
          >
            {t("admin.users.delete_action")}
          </ActionButton>
          <ActionButton disabled={loading} onClick={close} variant="outlined">
            {t("btn.cancel")}
          </ActionButton>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default AdvisorDeleteModal;
