import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router";
import LogoLoader from "../components/LogoLoader";
import NoMenuLayout from "../layout/NoMenuLayout";
import WebappCGUV from "../pages/WebappCGUV";
import RefuseInvitationPage from "../pages/RefuseInvitationPage";
import VivatechHome from "../pages/vivatech/VivatechHome";
import RedirectPlatform from "../components/RedirectPlatform";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const HomepageGOM = lazy(() => import("../pages/HomepageGOM"));
const CguPage = lazy(() => import("../pages/CguPage"));
const ForgotPwd = lazy(() => import("../pages/ForgotPwd"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const NotFound = lazy(() => import("../pages/NotFound"));
const SignupPage = lazy(() => import("../pages/SignupPage"));
const RedirectSignInPage = lazy(() => import("../pages/RedirectSignInPage"));
const AdfsPage = lazy(() => import("../pages/AdfsPage"));
const DeactivateReminder = lazy(() => import("../pages/DeactivateReminder"));

const BaseRoutes = () => {
  const isGom = process.env.REACT_APP_GOM === "true";

  return useRoutes([
    {
      path: "inc-cguv",
      element: (
        <Suspense fallback={<LogoLoader open={true} />}>
          <WebappCGUV />
        </Suspense>
      ),
    },
    {
      path: "",
      element: <NoMenuLayout />,
      children: [
        {
          path: "login",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <LoginPage />
            </Suspense>
          ),
        },
        {
          path: "adfs",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <AdfsPage />
            </Suspense>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <ForgotPwd />
            </Suspense>
          ),
        },
        {
          path: "sign-up",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <SignupPage />
            </Suspense>
          ),
        },
        {
          path: "refused-invitation",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RefuseInvitationPage />
            </Suspense>
          ),
        },
        isGom
          ? {
              path: "homepage",
              element: (
                <Suspense fallback={<LogoLoader open={true} />}>
                  <HomepageGOM />
                </Suspense>
              ),
            }
          : {},
        {
          path: "cgu",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <CguPage />
            </Suspense>
          ),
        },
        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "404",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <NotFound />
            </Suspense>
          ),
        },
        {
          path: "redirect",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RedirectSignInPage />
            </Suspense>
          ),
        },
        {
          path: "rdb",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <RedirectPlatform />
            </Suspense>
          ),
        },
        {
          path: "game",
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "deactivate_reminder",
          element: (
            <Suspense fallback={<LogoLoader open={true} />}>
              <DeactivateReminder />
            </Suspense>
          ),
        },
        {
          path: "demo",
          element: <VivatechHome />,
        },
        {
          path: "app/*",
          element: <Navigate to={isGom ? "/homepage" : "/login"} replace />,
        },
        {
          path: "/",
          element: <Navigate to={isGom ? "/homepage" : "/login"} replace />,
        },

        {
          path: "onboarding",
          element: <Navigate to="/login" replace />,
        },

        { path: "*", element: <Navigate to="/404" replace /> },
      ].filter((el: any) => el.path),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};

export default BaseRoutes;
