import { FormControl, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CampaignType } from "../../../@types/campaignType";
import { WorkType } from "../../../@types/workType";
import { autocompleteTypes } from "../../../_utils/autocompleteTypes";
import AutocompleteComponent from "../AutocompleteComponent";

const ControlledWorkAutocomplete: React.FC<{
  disabled?: boolean;
  offer?: boolean;
  control: any;
  clearErrors: any;
  required?: boolean;
  label?: string;
  placeholder?: string;
  folder?: CampaignType;
  workObject?: any;
  handleWork?: (e: WorkType) => void;
  style?: any;
  variant?: "filled" | "standard" | "outlined" | undefined;
}> = ({
  disabled,
  offer,
  control,
  clearErrors,
  required,
  label,
  placeholder,
  folder,
  workObject,
  handleWork,
  style,
  variant = "outlined",
}) => {
  const { t }: any = useTranslation("common");
  return (
    <FormControl sx={{ width: "100%" }}>
      <Controller
        name="work"
        control={control}
        rules={{
          required: { value: required ?? false, message: "errors.required" },
          validate: {
            required: (value) =>
              !required || value.label?.length > 0 ? true : "errors.required",
          },
        }}
        render={({ field: { value, onBlur }, formState: { errors } }) => (
          <>
            <AutocompleteComponent
              disabled={disabled}
              label={label}
              onBlur={onBlur}
              variant={variant}
              formError={Boolean(errors?.["work"])}
              placeholder={
                offer
                  ? "Tapez les trois premières lettres du métier"
                  : placeholder ?? t("autocomplete.placeholder.work_min")
              }
              type={autocompleteTypes.works}
              sendDataToParent={(e: any) => {
                handleWork?.(e);
              }}
              baseValue={folder?.work || workObject || value}
              onFocus={() => clearErrors("work")}
              sx={style}
            />
            {Boolean(errors?.["work"]) && (
              <Typography variant="body2" component="small" color="error">
                {errors["work"] ? t(errors["work"].message) : " "}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default ControlledWorkAutocomplete;
