import React, { useEffect, useState } from "react";
import AdminAdvisorBenefsDropList from "./AdminAdvisorBenefsDropList";
import AdvisorAutocomplete from "./AdvisorAutocomplete";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import RemoveAdvisorModal from "./RemoveAdvisorModal";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Utils } from "../../../../Utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomButtonModal from "../../../common/CustomBtnModal";
import Loader from "../../../../Loader";
import { toast } from "react-toastify";

interface AdminAdvisorBenefsListProps {
  setHasChanged: (hasChanged: boolean) => void;
}

const AdminAdvisorBenefsList: React.FC<AdminAdvisorBenefsListProps> = ({
  setHasChanged,
}) => {
  const [state, setState] = useState<any[]>([]);
  const [open, setOpen] = useState<any>(null);
  const { user_id } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (state) {
      setHasChanged(
        state.some((el: any) =>
          el.benefs.some((item: any) => item.advisorID !== el.id)
        )
      );
    }
  }, [setHasChanged, state]);

  const removeAdvisor = (
    index: any,
    bypass = Boolean(Utils.getCookie(`${user_id}_adminRemoveAdvisorModal`))
  ) => {
    const adv = state.find((el: any) => el.index === index);
    if (!adv) return;

    if (
      !bypass &&
      adv.benefs?.filter((el: any) => el.advisorID !== adv.id).length > 0
    ) {
      setOpen(index);
      return;
    }

    const newBenefs = adv.benefs.filter(
      (el: any) => el.advisorID !== adv.index
    );

    const newState = [...state];

    newBenefs.forEach((el: any) => {
      const oldAdv = newState.findIndex((ad: any) => ad.index === el.advisorID);
      if (oldAdv >= 0)
        newState[oldAdv].benefs = [...newState[oldAdv].benefs, el];
    });
    setOpen(null);
    setState(newState.filter((el: any) => el.index !== index));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      let apis: any = [];
      state.forEach((el) => {
        const moved = el.benefs
          .filter((bn: any) => el.index !== bn.advisorID)
          .map((bn: any) => ({
            candidate: bn.candidateId,
            from: bn.advisorID,
            to: el.id,
          }));
        apis = [...apis, ...moved];
      });

      if (apis.length === 0) {
        setLoading(false);
        return;
      }

      const res = await axios.post("recruiter_candidates/updates", {
        updates: apis,
      });

      if (res.status === 204) {
        const newState = [...state];
        newState.forEach((advisor: any) => {
          advisor.benefs.forEach((bn: any) => {
            if (advisor.id !== bn.advisorID) {
              bn.advisorID = advisor.id;
              bn.advisorName = advisor.advisor.fullName;
              bn.id = bn.candidateId + advisor.id;
            }
          });
        });
        setState(newState);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAutocomplete = async (value: any) => {
    try {
      if (state.find((el: any) => el.id === value.index)) {
        toast.warning(t("errors.already_added", { context }));
        return;
      }

      setLoading(true);
      const res = await axios.get(
        `recruiter/candidates?recruiter.id=${value.index}&pagination=false`
      );

      const responseArray = res?.data?.["hydra:member"] ?? [];

      const benefs = responseArray.map((el: any) => ({
        advisorID: value.index,
        candidateId: el.candidate.id,
        id: el.candidate.id + value.index,
        fullName: `${el.candidate.firstName} ${el.candidate?.lastName}`,
        email: el.candidate.email,
        infos: el.candidate,
        advisorName: value.title,
      }));

      const obj = {
        id: value.index,
        index: value.index,
        advisor: {
          fullName: value.title,
        },
        benefs,
        totalBenefs: benefs.length,
      };

      setState((current) => [...current, obj]);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const checkName = (name: string, term: string) => {
    var pattern = term
      .toLowerCase()
      .split("")
      .map((x) => {
        return `(?=.*${x})`;
      })
      .join("");

    var regex = new RegExp(`${pattern}`, "g");

    return name.toLowerCase().match(regex);
  };

  useEffect(() => {
    const newState = state.map((adv: any) => {
      return {
        ...adv,
        benefs: adv.benefs.map((el: any) => ({
          ...el,
          style: {
            ...(checkName(el.fullName, searchInput) ? {} : { display: "none" }),
          },
        })),
      };
    });

    setState(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "left", md: "center" },
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <AdvisorAutocomplete
          sendDataToParent={handleAutocomplete}
          disabled={loading}
        />

        {state.length > 0 && (
          <OutlinedInput
            sx={{
              width: "300px",
              marginLeft: { xs: "0px", md: "10px" },
              marginTop: { xs: "10px", md: "0px" },
            }}
            value={searchInput}
            placeholder={t("common_advisor.profile.search", { context })}
            onChange={(e) => setSearchInput(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => searchInput && setSearchInput("")}
                  edge="end"
                  disabled={!searchInput}
                >
                  {searchInput ? (
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  ) : (
                    <Box sx={{ width: "20px" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </Box>

      <div style={{ marginLeft: "10px" }} />

      {state.length < 1 && !loading && (
        <Typography sx={{ mt: 2 }} textAlign="center">
          {t("common_advisor.add_first", { context })}
        </Typography>
      )}

      <div
        id="drop-container"
        style={{
          position: "relative",
          overflow: loading ? "hidden" : "auto",
          minHeight: loading ? "200px" : "0px",
        }}
      >
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
        <AdminAdvisorBenefsDropList
          state={state}
          setState={setState}
          removeAdvisor={removeAdvisor}
          setHasChanged={setHasChanged}
        />
      </div>

      {Boolean(open) && (
        <RemoveAdvisorModal
          open={open}
          handleClose={() => setOpen(false)}
          confirm={removeAdvisor}
        />
      )}
      {state.length > 0 && (
        <div style={{ marginTop: "10px" }}>
          <CustomButtonModal
            onClick={handleSave}
            variant="contained"
            text={t(`btn.save`)}
          />
        </div>
      )}
    </div>
  );
};

export default AdminAdvisorBenefsList;
