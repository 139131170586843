import axios from "axios";
import chatAPI from "../../_api/_chatAPI";
import { setUserPartial } from "./userActions";

export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_BENEFS = "SET_BENEFS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_MERCURE_SOURCES = "SET_MERCURE_SOURCES";

export const SET_MESSAGES = "SET_MESSAGES";
export const GET_PREVIOUS_MESSAGES = "GET_PREVIOUS_MESSAGES";
export const REQUEST_UPDATE_CONVERSATION = "REQUEST_UPDATE_CONVERSATION";
export const REQUEST_UPDATE_MESSAGE = "REQUEST_UPDATE_MESSAGE";
export const UPD_CONVERSATION_MEMBER = "";
export const UPD_MESSAGE = "UPD_MESSAGE";

export const setConversations = (conversations: any) => {
  return {
    type: SET_CONVERSATIONS,
    conversations,
  };
};

export const setBenefs = (benefs: any) => {
  return {
    type: SET_BENEFS,
    benefs,
  };
};

export const setNotifications = (notifications: any) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
};

export const setMercureSources = (mercureSources: any) => {
  return {
    type: SET_MERCURE_SOURCES,
    mercureSources,
  };
};

export const setMessage = (message: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === message.convId
    );
    const copyConvs = [...conversations];

    if (myConvIndex >= 0) {
      const myConv = copyConvs[myConvIndex];
      myConv.messages.push(message);
      myConv.lastMessage = message;
      myConv.nbrMessages += 1;
      copyConvs[myConvIndex] = myConv;
    }

    dispatch(setConversations(copyConvs));
  };
};

export const updMessage = (message: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === message.convId
    );
    const copyConvs = [...conversations];

    if (myConvIndex >= 0) {
      const myConv = copyConvs[myConvIndex];
      const oldMessageIndex = myConv?.messages?.findIndex(
        (msg: any) => msg.id === message.id
      );
      myConv.messages[oldMessageIndex] = message;
      if (myConv.messages?.length - 1 === oldMessageIndex)
        myConv.lastMessage = message;
      copyConvs[myConvIndex] = myConv;
    }

    dispatch(setConversations(copyConvs));
  };
};

export const delMessage = (message: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === message.convId
    );
    const copyConvs = [...conversations];

    if (myConvIndex >= 0) {
      const myConv = copyConvs[myConvIndex];
      const oldMessageIndex = myConv?.messages?.findIndex(
        (msg: any) => msg.id === message.id
      );

      myConv.messages.splice(oldMessageIndex, 1);

      myConv.lastMessage = myConv?.messages[myConv.messages?.length - 1];
      myConv.nbrMessages = myConv.nbrMessages - 1;

      copyConvs[myConvIndex] = myConv;
    }

    dispatch(setConversations(copyConvs));
  };
};

export const setMessages = (messages: any, targetConversation: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === targetConversation.id
    );
    const copyConvs = [...conversations];

    messages.forEach((message: any) => {
      if (myConvIndex >= 0) {
        copyConvs[myConvIndex].messages.unshift(message);
        copyConvs[myConvIndex].lastMessage = message;
      }
    });

    dispatch(setConversations(copyConvs));
  };
};

export const requestGetConversation = (id: string) => {
  return async (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex((el: any) => el.id === id);
    const copyConvs = [...conversations];

    if (myConvIndex >= 0) {
      const myConv = copyConvs[myConvIndex];
      if (
        myConv?.messages?.length === 0 ||
        myConv?.messages?.[0]?.updatedAt === undefined
      ) {
        try {
          const res: any = await axios.get(
            chatAPI.getById(`messages`, { conversation: id })
          );
          // api order is DESC , reverse required
          myConv.messages = res.data?.["hydra:member"].reverse();
          copyConvs[myConvIndex] = myConv;
        } catch (err) {
          console.error("ERREUR CHARGEMENT MESSAGES DE LA CONV ", id);
        }
      }
    }
    dispatch(setConversations(copyConvs));
  };
};

export const requestGetPreviousMessages = (conversation: any) => {
  return async (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === conversation?.id
    );
    const copyConvs = [...conversations];

    if (myConvIndex >= 0) {
      const firstMessage = copyConvs[myConvIndex].messages[0];

      try {
        const res: any = await axios.get("chat/previous-messages", {
          params: {
            conversation: copyConvs[myConvIndex]["@id"],
            message: firstMessage["@id"],
          },
        });

        dispatch(setMessages(res.data?.["hydra:member"], conversation));
      } catch (err) {
        console.error("ERREUR CHARGEMENT MESSAGES DE LA CONV");
      }
    }
  };
};

export const requestUpdateConversation = (reqBody: any, conversation: any) => {
  return async () => {
    await axios.put(`chat/conversation/${conversation?.id}`, reqBody);
  };
};

export const requestUpdateMessage = (message: any) => {
  return async () => {
    await axios.put("chat/message/" + message?.id, {
      content: message?.content,
    });
  };
};

export const requestDeleteMessage = (message: any) => {
  return async () => {
    await axios.delete("chat/message/" + message?.id);
  };
};

export const requestClearNotificationByConversation = (conversation: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const { notifications } = getState().chat;

    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === conversation?.id
    );
    const myNotifIndex = notifications?.findIndex(
      (el: any) => el.id === conversation?.notification?.id
    );

    const copyConvs = [...conversations];
    const copyNotif = [...notifications];

    const notifId = conversation?.notification?.id;
    copyNotif.splice(myNotifIndex, 1);

    if (myConvIndex > -1) {
      const myConv = copyConvs[myConvIndex];
      delete myConv.notification;
      copyConvs[myConvIndex] = myConv;
    }

    axios.delete("chat/notification/" + notifId);

    dispatch(setConversations(copyConvs));
    dispatch(setNotifications(copyNotif));
  };
};

export const requestUpdateConnectionStatus = (new_status: any) => {
  return async (dispatch: any, getState: any) => {
    const { person_id } = getState().user;
    await axios.put(`people/${person_id}/update-status`, {
      status: new_status,
    });
  };
};

export const requestUserIsAlive = () => {
  return async (dispatch: any, getState: any) => {
    const { status } = getState().user;
    if (status !== undefined && !status.locked)
      axios.post("alive").catch((err) => console.warn("Can't post alive"));
  };
};

export const requestUserIsTyping = (convId: any) => {
  return () => {
    axios.post("typing/" + convId);
  };
};

export const addOrUpdConversation = (conversation: any) => {
  //just add ?
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const copyConvs = [...conversations];
    conversation.members = Object.values(conversation?.members);

    let test = copyConvs.find((el: any) => el.id === conversation?.id);
    if (!test) copyConvs.push(conversation);

    dispatch(setConversations(copyConvs));
  };
};

export const updConversationName = (convId: any, name: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const index = conversations.findIndex((el: any) => el.id === convId);
    const copyConvs = [...conversations];

    if (index !== -1) copyConvs[index].name = name;

    dispatch(setConversations(copyConvs));
  };
};

export const updConversationMembers = (newMembersObj: any, convId: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const index = conversations.findIndex((el: any) => el.id === convId);
    const copyConvs = [...conversations];

    copyConvs[index].members = Object.values(newMembersObj);

    dispatch(setConversations(copyConvs));
  };
};

export const updConversationMemberStatus = (r: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const copyConvs = [...conversations];

    const { person_id, status } = getState().user;

    if (r.person_id === person_id) {
      status.value = r.type === "joined" ? "connected" : "away";
      dispatch(setUserPartial({ status }));
    }
    // eslint-disable-next-line array-callback-return
    copyConvs.map((c: any, i: number) => {
      const member_index = copyConvs[i]?.members?.findIndex(
        (el: any) => el.id === r.person_id
      );

      if (member_index !== -1) {
        copyConvs[i].members[member_index].connectionStatus.value =
          r.type === "joined" ? "connected" : "away";
      }
    });

    dispatch(setConversations(copyConvs));
  };
};

export const updConversationTyping = (response: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const index = conversations.findIndex(
      (el: any) => el.id === response.conversation_id
    );
    const copyConvs = [...conversations];

    let curStamp = new Date().getTime();
    if (!Array.isArray(copyConvs[index].typingMembers))
      copyConvs[index].typingMembers = [];
    copyConvs[index].typingMembers.push({
      person_id: response?.person_id,
      stamp: new Date().getTime(),
    });
    copyConvs[index].typingMembers = copyConvs[index].typingMembers.filter(
      (e: any) => {
        return curStamp - e.stamp < 6000;
      }
    );

    dispatch(setConversations(copyConvs));
  };
};

export const addNotification = (notification: any) => {
  return (dispatch: any, getState: any) => {
    const { conversations } = getState().chat;
    const { notifications } = getState().chat;

    const myConvIndex = conversations?.findIndex(
      (el: any) => el.id === notification?.convId
    );
    const myNotifIndex = notifications?.findIndex(
      (el: any) => el.id === notification?.id
    );

    const copyConvs = [...conversations];
    const copyNotif = [...notifications];

    if (myNotifIndex > -1) {
      copyNotif[myNotifIndex] = notification;
    } else {
      copyNotif.push(notification);
    }

    if (myConvIndex > -1) {
      const myConv = copyConvs[myConvIndex];
      myConv.notification = notification;
      copyConvs[myConvIndex] = myConv;
    }

    dispatch(setConversations(copyConvs));
    dispatch(setNotifications(copyNotif));
  };
};

export const addMercureSource = (
  mercureToken: any,
  EventSourcePolyfill: any,
  url: any
) => {
  return (dispatch: any, getState: any) => {
    let eventSource: any;
    const { mercureSources } = getState().chat;
    const copyMS = mercureSources ? [...mercureSources] : [];

    const newSrc = copyMS.find((eSrc) => eSrc?.url === url) === undefined;

    if (mercureToken && newSrc) {
      eventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${mercureToken}`,
        },
      });
      //receiving a conv.message
      eventSource.onmessage = (e: any) => {
        const res = JSON.parse(e.data);

        if (res.type === "message" && res.method === "post") {
          dispatch(setMessage(res));
        } else if (res.type === "message" && res.method === "put") {
          dispatch(updMessage(res));
        } else if (res.type === "message" && res.method === "delete") {
          dispatch(delMessage(res));
        } else if (res.type === "members_update") {
          dispatch(updConversationMembers(res.members, res.convId));
        } else if (res.type === "joined" || res.type === "left") {
          dispatch(updConversationMemberStatus(res));
        } else if (res.type === "title_update") {
          dispatch(
            updConversationName(
              res?.conversation_id,
              res?.name ?? "Default conversation name"
            )
          );
        } else if (res.type === "typing") {
          dispatch(updConversationTyping(res));
        } else if (
          res.action === "new_message_notif" ||
          res.action === "new_conversation_notif"
        ) {
          res.convId = res.conversation;
          dispatch(addNotification(res));
        } else if (res.type === "add_conversation") {
          dispatch(addOrUpdConversation(res));
        } else {
          console.log("unHandle res.type => ", res.type);
        }
      };

      copyMS.push(eventSource);

      dispatch(setMercureSources(copyMS));

      return () => {
        eventSource?.close();
      };
    }
  };
};

export const clearMercureSources = () => {
  return (dispatch: any, getState: any) => {
    const { mercureSources } = getState().chat;
    const copyMS = mercureSources ? [...mercureSources] : [];

    copyMS.forEach((e: any) => {
      e?.close();
    });

    dispatch(setMercureSources([]));
  };
};
