import React from "react";
import AutocompleteComponent from "../AutocompleteComponent";
import { useTranslation } from "react-i18next";

const AddressAutocomplete: React.FC<any> = ({
  onChange,
  value,
  noLabel,
  disabled,
}) => {
  const { t } = useTranslation("common");

  const formatter = (data: any[]) => {
    return data.map((el: any) => ({
      ...el,
      label: el.properties.full_address,
      key: el.id,
    }));
  };

  return (
    <AutocompleteComponent
      disabled={disabled}
      baseValue={value}
      apiString="location/address"
      needleKey="q"
      dataFormatter={formatter}
      sendDataToParent={onChange}
      label={noLabel ? undefined : t("form.address")}
    />
  );
};

export default AddressAutocomplete;
