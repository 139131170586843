import { useTheme } from "@emotion/react";
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TagType } from "../../@types/tagType";
import useFetch from "../../hooks/useFetch";
import tagsAPI from "../../_api/_tagsAPI";
import FilterPopoverContainer from "../common/chip_filter/FilterPopoverContainer";
import BottomFilterContent from "../common/chip_filter/BottomFilterContent";

const WorkTagsFilter = ({ handleTags, baseValue }: any) => {
  const { t } = useTranslation("common");
  const theme: any = useTheme();
  const [close, setClose] = useState<any>(false);
  const [tagsFilter, setTagsFilter] = useState<TagType[]>([]);

  const [tagsList, setTagsList] = useState<any>([]);
  const [values, setValues] = useState<any>(null);
  //console.log("baseValue", baseValue);

  const { data } = useFetch(tagsAPI.get({ type: "work" }));

  const returnTitleChip = () => {
    const first = `${tagsList?.[0]}`;
    const second = `${tagsList?.[1]}`;
    return tagsList?.length > 2
      ? `${first}, ${second} +${tagsList?.length - 2}`
      : tagsList?.length > 1
      ? `${first}, ${second}`
      : tagsList?.length > 0
      ? `${first}`
      : t("tag.label_tags");
  };

  const handleChange = (newValue: any) => {
    const newArr = [...tagsList];
    if (newValue.length > 0) {
      const index = newArr.findIndex((el) => el === newValue);
      index > -1 ? newArr.splice(index, 1) : newArr.push(newValue);
    }
    if (newArr?.length > 0) {
      const tags: any = tagsFilter.filter((el: any) =>
        newArr.includes(el?.label)
      );
      const tagsId: Object[] = [];
      tags?.map((tag: TagType) =>
        tagsId?.push(
          tagsFilter?.filter((el: TagType) => el?.label === tag?.label)?.[0]?.[
            "@id"
          ]
        )
      );
      setValues({ ids: tagsId, items: newArr });
    } else {
      setValues({ ids: [], items: [] });
    }

    setTagsList(newArr);
  };

  const handleSubmit = () => {
    handleTags(values);
    setClose(!close);
  };

  const handleClear = () => {
    setValues({ ids: [], items: [] });
    handleTags({ ids: [], items: [] });
    setTagsList([]);
    setClose(!close);
  };

  useEffect(() => {
    if (data?.["hydra:member"]) {
      const newArr = data?.["hydra:member"];
      const filteredTags = newArr.filter(
        (tag: any) => tag.type === "App\\Entity\\Work\\Work"
      );
      filteredTags.forEach((tag: any) => (tag.value = tag.label));
      setTagsFilter(filteredTags);
    }
  }, [data]);

  useEffect(() => {
    // if (baseValue?.fullDataFilters?.tags?.length > 0) {
    //   const tags: any = tagsFilter.filter((el: any) =>
    //     baseValue?.fullDataFilters?.tags.includes(el?.label)
    //   );
    //   const tagsId: Object[] = [];
    //   tags?.map((tag: TagType) =>
    //     tagsId?.push(
    //       tagsFilter?.filter((el: TagType) => el?.label === tag?.label)?.[0]?.[
    //         "@id"
    //       ]
    //     )
    //   );
    // setValues({ ids: tagsId, items: baseValue?.fullDataFilters?.tags });
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseValue]);

  return (
    <>
      <FilterPopoverContainer
        title={returnTitleChip()}
        idPop="tags_popover"
        close={close}
        active={tagsList?.length > 0}
        content={
          <>
            {tagsFilter?.length > 0 ? (
              tagsFilter?.map((item: any) => (
                <MenuItem
                  sx={{
                    backgroundColor:
                      tagsList.indexOf(item.label) > -1 &&
                      theme.palette.primary[100],
                  }}
                  onClick={() => handleChange(item?.label)}
                  key={item.id}
                  value={item.label}
                >
                  <Checkbox checked={tagsList.indexOf(item.label) > -1} />
                  <ListItemText primary={t(item.label)} />
                </MenuItem>
              ))
            ) : (
              <Box sx={{ display: "flex", alignContent: "center", m: 2 }}>
                <Typography
                  sx={{
                    opacity: "0.7",
                  }}
                  variant="body2"
                >
                  {t("common_advisor.profile.no_tag")}
                </Typography>
              </Box>
            )}
            {tagsFilter?.length > 0 ? (
              <BottomFilterContent
                handleSubmit={handleSubmit}
                handleClear={handleClear}
                disabled={tagsList?.length === 0}
              />
            ) : null}
          </>
        }
      />
    </>
  );
};

export default WorkTagsFilter;
