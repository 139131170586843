import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import i18next from "i18next";
import { mergeDeep } from "./Utils";

const locales = ["fr", "en"];

const nav = navigator as any;
let lng = (
  localStorage.getItem("lng") ||
  nav.language ||
  nav["userLanguage"] ||
  "fr"
).split("-")[0];

if (!locales.includes(lng)) lng = locales[0];

if (!localStorage.getItem("lng")) localStorage.setItem("lng", lng);

export const i18nInit = {
  interpolation: { escapeValue: false }, // React already does escaping,
  lng,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    fr: {
      common: common_fr,
    },
  },
};

export const seti18n = (labels?: any) => {
  let config: any = { ...i18nInit };

  if (labels) {
    const resources = { ...config.resources };
    const merged = mergeDeep(resources, {
      fr: { common: labels.fr ?? {} },
      en: { common: labels.en ?? {} },
    });
    config.resources = merged;
  }

  i18next.init(config);

  applyCustomFormatters();
};

const applyCustomFormatters = () => {
  if (!i18next?.services?.formatter) return;
  i18next.services.formatter.add("lowercase", (value, lng, options) => {
    return value.toLowerCase();
  });

  i18next.services.formatter.add("uppercase", (value, lng, options) => {
    return value.toUpperCase();
  });

  i18next.services.formatter.add("plural", (value, lng, options) => {
    return handlePlural(options);
  });

  i18next.services.formatter.add("pluralLowercase", (value, lng, options) => {
    return handlePlural(options).toLowerCase();
  });

  i18next.services.formatter.add("pluralUppercase", (value, lng, options) => {
    return handlePlural(options).toUpperCase();
  });

  i18next.services.formatter.add("titlecase", (value, lng, options) => {
    return value.replace(/\w\S*/g, (txt: string) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  });

  i18next.services.formatter.add("capitalize", (value, lng, options) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  });
};

const handlePlural = (options: any) => {
  const otherKey = `${options.interpolationkey}_${options.context}_other`;
  const plural = i18next.t(`common:${otherKey}`);

  if (plural === otherKey) {
    return i18next.t(`common:${options.interpolationkey}_other`);
  }

  return plural;
};
