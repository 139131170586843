import {
  Modal,
  Card,
  Typography,
  Box,
  styled,
  IconButton,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";
import CustomButtonModal from "../common/CustomBtnModal";
import { useSelector } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import MailLockIcon from "@mui/icons-material/MailLock";
import peopleAPI from "../../_api/_people";
import { toast } from "react-toastify";
import axios from "axios";
import { toastError } from "../../_utils/toastError";

const IconBox = styled(Box)(({ theme }: any) => ({
  padding: "10px",
  width: "40px",
  height: "40px",
  borderRadius: theme.shape.borderRadius8,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const Body = styled("div")`
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  > .body-container {
    width: 100%;
    height: 100%;
  }
`;

const AskShareModal: React.FC<any> = ({
  open,
  close,
  profiles,
  onFinish,
  setSelectedBenef,
  folderShare,
}) => {
  const { t }: any = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const countProfiles = profiles?.length;

  const handleClose = () => {
    close();
    onFinish();
  };
  const sendRequest = async (objects: any) => {
    for (const object of objects) {
      if (folderShare) {
        try {
          await axios.patch(
            peopleAPI.getById(`${object.person.id}/send-share-partners`),
            {}
          );
          toast.success(t("success.request_sent"));
        } catch (err) {
          toastError();
        }
      } else {
        if (!object?.candidate?.otherData?.sharePartners) {
          try {
            await axios.patch(
              peopleAPI.getById(`${object.id}/send-share-partners`),
              {}
            );
            toast.success(t("success.request_sent"));
            setSelectedBenef && setSelectedBenef([]);
          } catch (err) {
            toastError();
          }
        }
      }
    }
    folderShare ? close() : handleClose();
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
        }}
      >
        <Box width="100%" sx={{ display: "flex", width: "100%", mt: 1, p: 1 }}>
          <Box
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <IconBox>
              <MailLockIcon sx={{ width: "20px", height: "20px" }} />
            </IconBox>
          </Box>

          <IconButton
            aria-label="close modal"
            sx={{ ml: "auto", mb: "auto", padding: "0" }}
            onClick={folderShare ? close : handleClose}
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <Body>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontSize: "21px" }}
            >
              {t(`common_advisor.profile.send_ask_accept_share`, {
                count: countProfiles,
                context,
              })}
            </Typography>
            <Typography variant="h4" color="primary"></Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {t("common_advisor.profile.text_ask_share", {
                context,
                count: countProfiles,
              })}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="center"
              sx={{ pt: 1, marginTop: "auto" }}
            >
              <CustomButtonModal
                secondary
                type="button"
                onClick={folderShare ? close : handleClose}
                variant="outlined"
                color="secondary"
                text={t(`btn.cancel`)}
              />
              <CustomButtonModal
                variant="contained"
                text={t(`btn.send`)}
                onClick={() => sendRequest(profiles)}
              />
            </Box>
          </Box>
        </Body>
      </Card>
    </Modal>
  );
};

export default AskShareModal;
