import React, { useState, useEffect } from "react";
import { Chip, Popover } from "@mui/material";

const FilterPopoverContainer = ({
  content,
  title,
  idPop,
  close,
  active,
  disabled,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null>(null);
  const handleClick = (event: any) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? idPop : undefined;
  useEffect(() => {
    setAnchorEl(null);
  }, [close]);

  return (
    <>
      <Chip
        aria-describedby={id}
        sx={{
          pl: 2,
          pr: 2,
          m: 0.5,
          cursor: "pointer",
          fontWeight: active ? "bold" : "",
          border: active ? "2px solid" : "1px solid",
        }}
        label={title}
        color="primary"
        variant="outlined"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {content}
      </Popover>
    </>
  );
};

export default FilterPopoverContainer;
