import { Box, Typography, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/useFetch";
import invitationsAPI from "../../../_api/_invitationsAPI";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import axios from "axios";
import { toast } from "react-toastify";
import StringAvatar from "../../common/StringAvatar";
import { getNumericDate } from "../../../_utils/getNumericDate";
import CustomTable from "../../common/table/CustomTable";
import AdvisorRemindModal from "./AdvisorRemindModal";
import { useSelector } from "react-redux";

const AdminAdvisorInvitedList = ({ reload, context }: any) => {
  const { t } = useTranslation("common");
  const itemsPerPage = useSelector(
    (state: any) => state.preferences.itemsPerPage
  );
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [selectedObject, setSelectedObject] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = React.useState({
    page: 1,
    itemsPerPage,
  });

  const { data, loading } = useFetch(
    invitationsAPI.get({
      page,
      context: `ROLE_ADMIN${context !== "advisor" ? "RECRUITER" : "ADVISOR"}`,
      reload,
    })
  );

  useEffect(() => {
    data?.["hydra:member"] && setListData(data?.["hydra:member"]);
  }, [data]);

  const { list, totalItems } = handleData(listData);

  tableHeaders[0].label = t(`admin.params.right_access.invited_${context}`);

  const handleParams = (newParams: any) => {
    setParams((current) => ({ ...current, ...newParams }));
  };

  const handleRemind = async (people: any) => {
    if (!people.canRemind) return;
    try {
      const res = await axios.patch(
        invitationsAPI.getById(`${people.id}/send-reminder`),
        {}
      );
      if (
        res.data.emailStatus === "failed" ||
        res.data.emailStatus === "blocked"
      ) {
        toast.error(
          t("common_advisor.notification.invite.remind_error", {
            name: people?.firstName + " " + people?.lastName,
          })
        );
      } else if (
        res.data.emailStatus === "sent" ||
        res.data.emailStatus === "pending"
      ) {
        const ids = selectedObject.map((el: any) => el["@id"]);
        setListData((current: any) =>
          current.map((el: any) => {
            if (ids.includes(el["@id"])) el.reminderSentAt = new Date();
            return el;
          })
        );
        toast.success(t("common_advisor.notification.invite.sent"));
      }
    } catch (err: any) {
      toast.error(
        err?.status === 412
          ? t("common_advisor.notification.invite.remind_error", {
              name: people?.firstName + " " + people?.lastName,
            })
          : t("errors.error_append")
      );
    } finally {
      setOpenModal(false);
      setSelected([]);
      setSelectedObject([]);
    }
  };

  const actions = [
    Boolean(selectedObject.find((el: any) => el.canRemind)) && {
      name: "Relancer",
      onClick: () => setOpenModal(true),
      icon: <NotificationsActiveIcon color="primary" />,
      color: "primary",
    },
  ].filter((el) => el);

  return (
    <div>
      {data?.["hydra:totalItems"] > 0 && (
        <CustomTable
          data={list}
          headers={tableHeaders}
          totalItems={totalItems}
          rowsPerPageOptions={[5, 10, 25]}
          loading={loading}
          actions={actions}
          page={params.page - 1}
          itemsPerPage={params.itemsPerPage}
          onItemsPerPageChange={(newItemsPerPage: number) =>
            handleParams({ itemsPerPage: newItemsPerPage, page: 1 })
          }
          onPageChange={(newPage: number) =>
            handleParams({ page: newPage + 1 })
          }
          onSelect={setSelected}
          selected={selected}
          onSelectObject={setSelectedObject}
        />
        // <Table sx={{ mt: 4 }} size="small" aria-label="a dense table">
        //   <TableHead>
        //     <TableRow>
        //       <TableCell align="center">
        //         {t(`admin.params.right_access.invited_${context}`)}
        //       </TableCell>
        //       <TableCell align="center">
        //         {t("admin.params.right_access.roles")}
        //       </TableCell>
        //       <TableCell align="center">
        //         {t("admin.params.right_access.mail")}
        //       </TableCell>
        //       <TableCell align="center">
        //         {t("admin.params.right_access.mail_status")}
        //       </TableCell>
        //       <TableCell align="center">
        //         {t("admin.params.right_access.invite_date")}
        //       </TableCell>
        //       <TableCell align="center">{t("btn.remind")}</TableCell>
        //     </TableRow>
        //   </TableHead>
        //   <TableBody>
        //     {list?.map((people: any) => (
        //       <TableRow
        //         key={people.id}
        //         sx={{
        //           "&:last-child td, &:last-child th": { border: 0 },
        //           height: 50,
        //         }}
        //       >
        //         <TableCell component="th" scope="row">
        //           <Box display="flex" alignItems="center">
        //             <StringAvatar
        //               size={35}
        //               name={people?.firstName + " " + people?.lastName}
        //             />
        //             <Typography variant="body2" sx={{ ml: 1 }}>
        //               {`${people.firstName} ${people.lastName}`}
        //             </Typography>
        //           </Box>
        //         </TableCell>
        //         <TableCell>
        //           <Box display="flex" flexWrap="wrap" justifyContent="center">
        //             {people.roles.map((role: string, index: number) => (
        //               <div key={role}>
        //                 {t(
        //                   role.substring(0, 5) === "ROLE_"
        //                     ? `roles.${role
        //                         .substring(5, role?.length)
        //                         .toLowerCase()}`
        //                     : ""
        //                 )}
        //                 {role.substring(0, 5) === "ROLE_" &&
        //                 index + 1 !== people?.roles?.length
        //                   ? " / "
        //                   : ""}
        //               </div>
        //             ))}
        //           </Box>
        //         </TableCell>
        //         <TableCell>{people.email}</TableCell>
        //         <TableCell>
        //           {t(`common_advisor.profile.email_status.${people.emailStatus}`)}{" "}
        //           {people.reminderSentAt && <>({t("revived")})</>}
        //         </TableCell>
        //         <TableCell>
        //           {people.reminderSentAt || people.sendDate
        //             ? getNumericDate(
        //                 new Date(people.reminderSentAt ?? people.sendDate)
        //               )
        //             : "-"}
        //         </TableCell>
        //         <TableCell>
        //           <IconButton
        //             onClick={() => handleRemind(people)}
        //             aria-label="send remind invitation"
        //           >
        //             <NotificationsActiveIcon color="primary" />
        //           </IconButton>
        //         </TableCell>
        //       </TableRow>
        //     ))}
        //   </TableBody>
        // </Table>
      )}
      <AdvisorRemindModal
        open={openModal}
        close={setOpenModal.bind(this, false)}
        remindUser={selectedObject}
        handleRemind={handleRemind}
      />
      {!loading && data && data?.["hydra:totalItems"] > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data?.["hydra:totalItems"]}
          rowsPerPage={10}
          page={page - 1}
          onPageChange={(event: any, newPage: number) => setPage(newPage + 1)}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

const handleData = (data: any) => {
  return {
    list: (data ?? []).map((el: any) => {
      return {
        ...el,
        fullname: <FullName people={el} />,
        role: <Role people={el} />,
        email: el.email,
        status: <Status people={el} />,
        sendAt:
          el.reminderSentAt || el.sendDate
            ? getNumericDate(new Date(el.reminderSentAt ?? el.sendDate))
            : "-",
        canRemind: el.reminderSentAt
          ? new Date() >
            new Date(new Date(el.reminderSentAt).getTime() + 10 * 60000)
          : true,
      };
    }),
    totalItems: data.length,
  };
};

const FullName: React.FC<{
  people: any;
}> = ({ people }) => {
  return (
    <Box display="flex" alignItems="center">
      <StringAvatar
        size={35}
        name={people?.firstName + " " + people?.lastName}
      />
      <Typography variant="body2" sx={{ ml: 1 }}>
        {`${people.firstName} ${people.lastName}`}
      </Typography>
    </Box>
  );
};

const Role: React.FC<{
  people: any;
}> = ({ people }) => {
  const { t } = useTranslation("common");
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {people.roles.map((role: string, index: number) => (
        <div key={role}>
          {t(
            role.substring(0, 5) === "ROLE_"
              ? `roles.${role.substring(5, role?.length).toLowerCase()}`
              : ""
          )}
          {role.substring(0, 5) === "ROLE_" &&
          index + 1 !== people?.roles?.length
            ? " / "
            : ""}
        </div>
      ))}
    </Box>
  );
};
const Status: React.FC<{
  people: any;
}> = ({ people }) => {
  const { t } = useTranslation("common");
  return (
    <Box>
      {t(`common_advisor.profile.email_status.${people.emailStatus}`)}{" "}
      {people.reminderSentAt && <>({t("revived")})</>}
    </Box>
  );
};

const tableHeaders = [
  {
    id: "fullname",
    numeric: false,
    label: "table.labels.invited_advisor",
    sortable: false,
    align: "left",
  },
  {
    id: "role",
    numeric: false,
    label: "table.labels.roles",
    sortable: false,
    align: "left",
  },
  {
    id: "email",
    numeric: false,
    label: "table.labels.email",
    sortable: false,
    align: "left",
  },
  {
    id: "status",
    numeric: false,
    label: "table.labels.email_status",
    sortable: false,
    align: "left",
  },
  {
    id: "sendAt",
    numeric: false,
    label: "table.labels.sent_date",
    sortable: false,
    align: "left",
  },
];

export default AdminAdvisorInvitedList;
