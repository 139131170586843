import { Typography, IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";

const Title = styled(Typography)(({ theme }: any) => ({
  padding: "4px 13px",
  borderRadius: theme.shape.borderRadius6,
}));

const WorkSkillTitle: React.FC<any> = ({
  type,
  context,
  handleEdit,
  compare,
  candEdit,
}) => {
  const { t } = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        mt: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          background: type === "technical" ? "#DAC2F4" : "#fff1c2",
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          width: 36,
          height: 36,
          mr: 1,
        }}
      >
        <img
          style={{
            width: "20px",
          }}
          alt="skills icon"
          src={
            "/static/icons/skills" +
            (type === "technical" ? "" : "_abilities") +
            "_logo.svg"
          }
        />
      </Box>
      <Title
        variant="h5"
        sx={{
          backgroundColor: type === "technical" ? "#FBF9FD" : "#FFFDF6",
        }}
      >
        {t(
          context === "recruiter"
            ? compare
              ? `common_advisor.folder.matching_${
                  type === "technical" ? "skills" : "abilities"
                }`
              : `tests.${
                  type === "technical" ? "technical_skills" : "comp_skills"
                }`
            : `work_sheet.expected_${type}`
        )}
      </Title>
      {context === "recruiter" && !compare && candEdit && (
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => handleEdit()}
          aria-label="edit"
        >
          <EditIcon color="primary" />
        </IconButton>
      )}
    </Box>
  );
};

export default WorkSkillTitle;
