import { Avatar, Box, Container, Typography } from "@mui/material";
import { Create } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { peopleType } from "../../@types/people";
import StyledButton from "../styled/StyledButton";
import TestBadge from "./badges/TestBadge";
import ProfileEditModal from "./ProfileEditModal";
import StringAvatar from "../common/StringAvatar";
import { shallowEqual, useSelector } from "react-redux";
import SocialNetworkLink from "./SocialNetworkLink";
import DownloadMenu from "./DownloadMenu";
import AccessMenu from "./AccessMenu";
import Status from "./Status";

const globalTransition = { transition: "all 0.2s ease-in-out" };

const ProfileHeader: React.FC<{
  profileData: peopleType | any;
  notCollab: boolean;
  completeness: any;
  matchingPage?: boolean;
  tabChange?: any;
  third?: boolean;
  thirdId?: string;
  handleStatus?: (status: any) => void;
}> = ({
  profileData,
  notCollab,
  completeness,
  matchingPage,
  tabChange,
  third,
  handleStatus,
}) => {
  const { context, isCollab } = useSelector((state: any) => state.auth);
  const home = useSelector((state: any) => state.home, shallowEqual);
  const theme: any = useTheme();
  const Root = styled(Box)(({ theme }: any) => ({
    border: "1px solid " + theme.palette.grey[300],
    borderRadius: 10,
    overflow: "hidden",
  }));

  const anonymous = profileData?.anonymous;
  const thirdParty = useSelector(
    (state: any) => state.auth.context === "third_party"
  );

  const Large = styled(Box)(({ theme }: any) => ({
    width: theme.spacing(23),
    height: theme.spacing(23),
    marginTop: "-130px",
    ...globalTransition,
    "&.small": {
      width: "140px",
      height: "140px",
      marginTop: "-90px",
    },
  }));

  const LargeAvatar = styled(Avatar)(({ theme }: any) => ({
    width: theme.spacing(23),
    height: theme.spacing(23),
    marginTop: "-130px",
    ...globalTransition,
    "&.small": {
      width: "140px",
      height: "140px",
      marginTop: "-90px",
    },
    ...(anonymous && { filter: "blur(8px)" }),
  }));

  const AvatarContainer = styled(Box)(({ theme }: any) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
    "&:hover $completion": {
      transform: "scale(1)",
    },
  }));

  const Completion = styled(Box)(({ theme }: any) => ({
    position: "absolute",
    top: -10,
    right: 10,
    borderRadius: "50%",
    height: 50,
    width: 50,
    color: "white",
    border: "2px solid white",
    background: theme.palette.secondary.main,
    display: "grid",
    alignItems: "center",
    ...globalTransition,
    userSelect: "none",
    "&.small": {
      transform: "scale(0.75)",
    },
  }));

  const Banner = styled(Box)(({ theme }: any) => ({
    backgroundImage:
      "url('https://images.unsplash.com/photo-1515155075601-23009d0cb6d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80')",
    height: "180px",
    width: "100%",
    ...globalTransition,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    "&.editable": {
      cursor: "pointer",
    },
    "&.small": {
      height: context === "recruiter" ? "120px" : "86px",
    },
    position: "relative",
  }));

  const Content = styled(Container)(({ theme }: any) => ({
    display: "flex",
    marginTop: theme.spacing(2),
  }));

  const Actions = styled(Box)(({ theme }: any) => ({
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 auto",
    },
  }));

  const { t } = useTranslation("common");
  const { tab } = useParams();

  const initals = `${profileData?.firstName?.charAt(
    0
  )} ${profileData?.lastName?.charAt(0)}`.toUpperCase();

  const [openEdit, setOpenEdit] = useState(false);

  const [testsKeys, setTestsKeys] = useState<any>([]);

  const [hasB5orDynamics, setHasB5orDynamics] = useState(false);

  useEffect(() => {
    if (completeness?.completenessOfProfile) {
      const keys = Object.keys(completeness.completenessOfProfile);
      setTestsKeys(keys);
      setHasB5orDynamics(
        completeness?.completenessOfProfile?.hasDynamic ||
          completeness?.completenessOfProfile?.hasBigFive
      );
    }
  }, [completeness]);

  // const handleSelectChange = (event: any) => {
  //   setSelectedStatus(event.target.value);
  //   setNewStatus(event.target.value);

  //   const statusObj = statusPossible?.find(
  //     (el: any) => el.code === event.target.value
  //   );
  //   console.log("selectedStatus", selectedStatus);
  //   console.log(statusObj);
  // };

  return (
    <Root>
      <Banner
        className={`${tab !== "overview" && !matchingPage ? "small" : ""}`}
      >
        {/* {(context === "recruiter" || context === "advisor") && (
          <ProfileTagList
            personId={profileData.id}
            small={tab !== "overview"}
          />
        )} */}
      </Banner>
      <Content sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}>
        <AvatarContainer>
          {profileData.image?.contentUrl ? (
            <LargeAvatar
              sx={{ border: "8px solid white" }}
              className={` ${
                tab !== "overview" && !matchingPage ? "small" : ""
              }`}
              alt="Profile picture"
              src={profileData.image?.contentUrl}
            >
              {initals}
            </LargeAvatar>
          ) : (
            <Large
              className={` ${
                tab !== "overview" && !matchingPage ? "small" : ""
              }`}
            >
              <StringAvatar
                profile
                size={tab !== "overview" && !matchingPage ? 124 : 168}
                name={`${profileData.firstName} ${profileData.lastName}`}
                sx={{ ...(anonymous && { filter: "blur(8px)" }) }}
              />
            </Large>
          )}
          {!third && !thirdParty && (
            <Completion
              className={` ${
                tab !== "overview" && !matchingPage ? "small" : ""
              }`}
            >
              <Typography sx={{ textAlign: "center" }} variant="subtitle1">
                {profileData.completenessPercent}%
              </Typography>
            </Completion>
          )}
          <Box textAlign="left">
            <Box sx={{ mt: 0.5 }}>
              <Box display="flex" flexDirection="column">
                {!notCollab && (
                  <StyledButton
                    onClick={() => setOpenEdit(true)}
                    pl={2}
                    pr={2}
                    pt={1}
                    pb={1}
                  >
                    <Create color="primary" sx={{ mr: 1, width: 20 }} />
                    <Typography color="primary" variant="body2">
                      {t("profilepage.edit")}
                    </Typography>
                  </StyledButton>
                )}
                {/* <DownloadPdfButton
                  notCollab={notCollab}
                  type="cv"
                  person={profileData}
                  backgroundColor={theme.palette.grey[500_8]}
                />
                <Box sx={{ marginTop: "5px" }} />
            
                {hasB5orDynamics && (
                  <DownloadPdfButton
                    notCollab={notCollab}
                    type="synthesis"
                    person={profileData}
                    backgroundColor={theme.palette.grey[500_8]}
                  />
                )} */}
              </Box>

              {context === "advisor" && !third && <AccessMenu />}
            </Box>
          </Box>
        </AvatarContainer>

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ ...(anonymous && { filter: "blur(4px)" }) }}
          >{`${profileData.firstName} ${profileData.lastName}`}</Typography>

          <Typography variant="body2">
            {third
              ? profileData?.company?.name
              : profileData.actualWork ?? t("profilepage.no_work")}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              width: "fit-content",
              p: "4px 8px",
              mr: "auto",
              mt: 0.5,
              borderRadius: "3px",
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: theme.palette.grey[500_8],
            }}
          >
            {profileData.address ? (
              <>{profileData.address}</>
            ) : (
              t("profilepage.no_location")
            )}
          </Typography>
          {completeness && !third && (
            <Box sx={{ mt: 1, mb: 2 }}>
              <Box display="flex">
                {completeness?.completenessOfProfile?.needGamified &&
                  home?.paramjson?.tests?.gamified && (
                    <TestBadge
                      pending={
                        completeness?.completenessOfProfile?.expectedData
                          ?.gamified
                      }
                      todo={!completeness?.completenessOfProfile?.hasDynamic}
                      type="gamified"
                      size={35}
                      padding={0}
                      tabChange={tabChange}
                    />
                  )}

                {(testsKeys.includes("hasBigFive") ||
                  context === "advisor" ||
                  context === "recruiter") &&
                  home?.paramjson?.tests?.personality && (
                    <TestBadge
                      askTest
                      pending={
                        completeness?.completenessOfProfile?.expectedData
                          ?.personality &&
                        !completeness?.completenessOfProfile?.hasBigFive
                      }
                      remakeTest
                      person={profileData}
                      todo={!completeness.completenessOfProfile["hasBigFive"]}
                      type="Big5"
                      tabChange={tabChange}
                    />
                  )}
                {(testsKeys.includes("hasCultureFit") ||
                  context === "advisor" ||
                  context === "recruiter") &&
                  home?.paramjson?.tests?.motivations && (
                    <TestBadge
                      pending={
                        completeness?.completenessOfProfile?.expectedData?.cf
                      }
                      askTest
                      person={profileData}
                      todo={
                        !completeness.completenessOfProfile["hasCultureFit"]
                      }
                      type="CultureFit"
                      tabChange={tabChange}
                    />
                  )}
                {(testsKeys.includes("hasDynamic") ||
                  context === "advisor" ||
                  context === "recruiter") &&
                  !completeness.completenessOfProfile.needGamified &&
                  home?.paramjson?.tests?.dynamics && (
                    <TestBadge
                      pending={
                        completeness?.completenessOfProfile?.expectedData?.inc
                      }
                      askTest
                      person={profileData}
                      todo={!completeness.completenessOfProfile["hasDynamic"]}
                      type="Inc"
                      tabChange={tabChange}
                    />
                  )}

                {(testsKeys.includes("hasMood") ||
                  context === "advisor" ||
                  context === "recruiter") &&
                  home?.paramjson?.tests.mood && (
                    <TestBadge
                      pending={
                        completeness?.completenessOfProfile?.expectedData?.mood
                      }
                      todo={!completeness.completenessOfProfile["hasMood"]}
                      type="mood"
                      person={profileData}
                      size={35}
                      padding={0}
                      askTest
                    />
                  )}
              </Box>
            </Box>
          )}
          {third && <Box marginBottom={2}></Box>}
        </Box>
        {context === "cre" && !third && profileData ? (
          <>
            <Status
              personId={profileData.id}
              currentStatus={profileData.status}
              updateStatus={handleStatus}
              profile
            />
          </>
        ) : null}

        <Actions>
          {/* {!notCollab && (
            <StyledButton
              onClick={() => setOpenEdit(true)}
              pl={2}
              pr={2}
              pt={1}
              pb={1}
            >
              <Create color="primary" sx={{ mr: 1, width: 20 }} />
              <Typography color="primary" variant="body2">
                {t("profilepage.edit")}
              </Typography>
            </StyledButton>
          )} */}
          {!matchingPage &&
            !third &&
            !anonymous &&
            ((home?.paramjson?.candidatePdf && isCollab) || !isCollab) && (
              <Box sx={{ mt: 1 }}>
                {/* <DownloadPdfButton
                  notCollab={notCollab}
                  type="selection"
                  person={profileData}
                  backgroundColor={theme.palette.grey[500_8]}
                /> */}
                <DownloadMenu
                  notCollab={notCollab}
                  profileData={profileData}
                  hasB5orDynamics={hasB5orDynamics}
                />
              </Box>
            )}
          {!anonymous && !third && (
            <Box sx={{ mt: 1 }}>
              <SocialNetworkLink profileData={profileData} />
            </Box>
          )}
        </Actions>
      </Content>
      {!third && (
        <ProfileEditModal open={openEdit} close={() => setOpenEdit(false)} />
      )}
    </Root>
  );
};
export default ProfileHeader;
